const mainTheme = {
  color: {
    primary: '#636EFF',
    secondary: '#F38D11',
    grey: '#D6E1FD',
    dark: '#1C1C1C',
    light: '#FFFFFF',
  },

  font: {
    family: {
      primary: 'poppins, sans-serif;',
      secondary: 'rubrik-new, sans-serif',
    },

    size: {
      tiny: '10px',
      small: '14px',
      medium: '16px',
      big: '20px',
      large: '28px',
      huge: '30px',
      great: '35px',
      mega: '40px',
      ultra: '50px',
      giga: '60px',
    },

    weight: {
      light: 300,
      regular: 400,
      bold: 700,
      extraBold: 800,
    },
  },
}

export default mainTheme
