/* eslint-disable react/no-array-index-key */
import React from 'react'
import styled from 'styled-components'
import { motion, AnimatePresence } from 'framer-motion'

// Components
import NavLink, { isPartiallyActive } from 'components/shared/NavLink'
import Submenu from 'components/layout/Header/Submenu'
import Circle from 'components/elements/Vectors/Circle'

// Images
import Chevron from 'img/chevron.inline.svg'

const Item = styled(motion.li)`
  & > div {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
  }

  & div > a {
    color: ${(props) => props.theme.color.light};
    font-family: ${({ theme }) => theme.font.family.primary};
    font-weight: ${({ theme }) => theme.font.weight.light};

    &[aria-current] {
      font-weight: ${({ theme }) => theme.font.weight.bold};
    }

    &:hover {
      opacity: 0.7;
    }
  }

  @media (min-width: 1200px) {
    margin-left: 3rem;
    margin-right: 0.8rem;
  }

  @media (max-width: 1199px) {
    margin-left: 1rem;
    margin-right: 0.5rem;
  }

  @media (max-width: 991px) {
    padding: 4px 0;

    & div > a {
      font-size: 20px;
      line-height: 20px;
      font-weight: ${({ theme }) => theme.font.weight.bold};
    }
  }
`

interface MenuProps {
  fields: GatsbyTypes.WpComponent
}

const Menu = ({ fields }: MenuProps) => {
  const [open, setOpen] = React.useState<number>(-1)

  return (
    <>
      {fields.header?.menuHeader?.map((l, index) => {
        const isPartiallyCurrent =
          typeof window !== `undefined` &&
          window.location.pathname.indexOf(l?.link?.url || '#') !== -1

        const isActive =
          isPartiallyActive({
            isPartiallyCurrent,
            location: typeof window !== `undefined` && window.location,
            href: l?.link?.url,
          })['aria-current'] === 'page'
        return (
          <Item
            onHoverStart={() => setOpen(index)}
            onHoverEnd={() => setOpen(-1)}
            key={index}
          >
            <div role="button" tabIndex={-1}>
              <NavLink
                to={l?.link?.url || '/'}
                className="d-flex align-items-center"
              >
                {isActive && (
                  <Circle
                    identifier="menu-circle"
                    width={14}
                    height={14}
                    strokeWidth={99}
                    responsive={false}
                  />
                )}
                <span className="ms-2 mt-1">{l?.link?.title}</span>
              </NavLink>
              {l?.submenu && (
                <motion.div
                  onClick={() =>
                    open === index ? setOpen(index) : setOpen(-1)
                  }
                  onTap={
                    open === index ? () => setOpen(-1) : () => setOpen(index)
                  }
                  role="button"
                  tabIndex={0}
                  onKeyDown={() => setOpen(index)}
                  className="ms-1"
                >
                  <Chevron
                    className="transition-all"
                    style={
                      open === index
                        ? {
                            transform: 'rotate(180deg)',
                          }
                        : {
                            transform: 'rotate(0deg)',
                          }
                    }
                  />
                </motion.div>
              )}
            </div>
            {l?.submenu && (
              <AnimatePresence>
                {open === index && <Submenu fields={l.submenu} />}
              </AnimatePresence>
            )}
          </Item>
        )
      })}
    </>
  )
}

export default Menu
