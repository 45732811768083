/* eslint-disable import/no-unresolved */
import React, { useEffect, useState } from 'react'
import { motion } from 'framer-motion'
import styled, { css } from 'styled-components'
import { useStaticQuery, graphql } from 'gatsby'

// Images
import Logo from 'img/logo.inline.svg'

// Components
import NavLink from 'components/shared/NavLink'
import Topmenu from 'components/layout/Header/Topmenu'
import Mobile from 'components/layout/Header/Mobile'
import Desktop from 'components/layout/Header/Desktop'

const StyledHeader = styled(motion.nav)<{
  isSticky: boolean
  notFound: boolean
}>`
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  z-index: 10;

  ${(props) =>
    props.isSticky || props.notFound
      ? css`
          background-color: ${props.theme.color.primary};
          box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
        `
      : css`
          background-color: transparent;
        `}
`

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
`

const Brand = styled(NavLink)<{ isSticky: boolean }>`
  font-size: 0;

  & > svg {
    transition: all ease-in-out 0.15s;

    ${(props) =>
      !props.isSticky
        ? css`
            height: 81px;
            width: 185px;
          `
        : css`
            height: 61px;
            width: 145px;
          `}
  }

  &:hover {
    opacity: 0.85;
  }
`

const TopmenuWrapper = styled(motion.div)<{ isSticky?: boolean }>`
  height: 30px;
  background-color: ${({ theme }) => theme.color.light};
`

interface HeaderProps {
  setIsScrollable: Function
  isSticky?: boolean
}

const Header = ({ setIsScrollable, isSticky = false }: HeaderProps) => {
  const [notFound, setNotFound] = useState(false)
  // eslint-disable-next-line
  const query = useStaticQuery<GatsbyTypes.headerQueryQuery>(graphql`
    query headerQuery {
      wpComponent(databaseId: { eq: 97 }) {
        header {
          topmenuHeader {
            phonenumber {
              title
              url
            }
            support {
              link {
                title
                url
              }
              submenu {
                link {
                  title
                  url
                }
              }
            }
          }
          menuHeader {
            link {
              title
              url
            }
            submenu {
              link {
                title
                url
              }
            }
          }
        }
      }
    }
  `)

  const fields: any = query.wpComponent

  useEffect(() => {
    if (
      typeof window !== 'undefined' &&
      window.location.pathname.includes('404')
    ) {
      setNotFound(true)
    }
  }, [])

  return (
    <StyledHeader isSticky={isSticky} notFound={notFound}>
      <TopmenuWrapper
        className="w-100"
        isSticky={isSticky}
        initial={{ height: 30 }}
        animate={isSticky ? { height: 20 } : { height: 30 }}
      >
        <Topmenu fields={fields} isSticky={isSticky} />
      </TopmenuWrapper>

      <Container className="container py-2 py-lg-0">
        <Brand to="/" isSticky={isSticky}>
          <Logo />
          Ga naar home
        </Brand>

        <Mobile fields={fields} setIsScrollable={setIsScrollable} />
        <Desktop fields={fields} isSticky={isSticky} />
      </Container>
    </StyledHeader>
  )
}

export default Header
