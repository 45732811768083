import React, { ReactNode } from 'react'
import styled, { css } from 'styled-components'
import ButtonShell from 'components/elements/Buttons/ButtonShell'

export const ButtonPrimaryCSS = css`
  background-color: ${(props) => props.theme.color.secondary};
  border: 3px solid ${({ theme }) => theme.color.secondary};
  display: inline-block;
  border-radius: 50px;
  /* min-width: 200px; */
  transition: all 0.2s ease-in-out;

  & > a,
  & > button {
    color: ${(props) => props.theme.color.light} !important;
    padding: 6px 22px;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    font-family: ${({ theme }) => theme.font.family.primary};
    line-height: 24px;
    font-weight: ${({ theme }) => theme.font.weight.bold};
    text-decoration: none !important;

    @media (min-width: 576px) {
      font-size: ${({ theme }) => theme.font.size.medium};
    }

    @media (max-width: 575px) {
      text-align: center;
      font-size: ${({ theme }) => theme.font.size.small};
    }
  }

  &:hover {
    background-color: #eda245;
  }
`

const StyledButton = styled.span`
  ${ButtonPrimaryCSS};
`

interface ButtonPrimaryProps {
  children: ReactNode
  isAnchor?: boolean
  isCustom?: boolean
  to: string
  className?: string
  target?: string
}

const ButtonPrimary = ({
  isAnchor = false,
  isCustom = false,
  to,
  children,
  className = '',
  target = '',
}: ButtonPrimaryProps) => (
  <StyledButton className={className}>
    {isCustom ? (
      children
    ) : (
      <ButtonShell to={to} isAnchor={isAnchor} target={target}>
        {children}
      </ButtonShell>
    )}
  </StyledButton>
)

export default ButtonPrimary
