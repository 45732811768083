/* eslint-disable react/no-array-index-key */
import React from 'react'
import styled from 'styled-components'
import { motion } from 'framer-motion'

// Components
import NavLink from 'components/shared/NavLink'
import ParseContent from 'components/shared/ParseContent'

interface SubmenuProps {
  // eslint-disable-next-line
  fields: readonly GatsbyTypes.Maybe<GatsbyTypes.WpComponent_Header_menuHeader_submenu>[]
}

const Item = styled.li`
  list-style: none;

  & > a {
    color: ${(props) => props.theme.color.primary};

    &[aria-current] {
      color: ${({ theme }) => theme.color.secondary};
    }

    &:hover {
      text-decoration: underline;
    }
  }

  @media screen and (max-width: 991px) {
    padding: 4px 0;

    & > a {
      font-size: 16px;
      line-height: 16px;
      font-weight: ${({ theme }) => theme.font.weight.bold};
      color: ${({ theme }) => theme.color.light};
    }
  }
`

const StyledSubmenu = styled(motion.div)`
  overflow: hidden;

  @media screen and (min-width: 992px) {
    position: absolute;
    overflow: hidden;
    padding-top: 15px;

    & > div {
      background-color: ${({ theme }) => theme.color.light};
      box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.1), 0 0 1px 0 rgba(0, 0, 0, 0.1);
      padding: 15px;
      border-radius: 24px;
    }
  }

  @media screen and (max-width: 991px) {
    position: relative;
    padding-top: unset;
    width: 100%;

    & > div {
      padding: 2px 5px 2px 10px;
      padding-top: 1rem;
      padding-bottom: 0.7rem;
    }
  }
`

const Content = styled(ParseContent)`
  @media (max-width: 991.98px) {
    font-size: 20px !important;
  }
`

const Submenu = ({ fields }: SubmenuProps) => (
  <StyledSubmenu
    initial={{ height: 0 }}
    animate={{ height: 'fit-content' }}
    exit={{ height: 0 }}
  >
    <div>
      {fields.map((f, index) => (
        <Item className="mb-2 mb-lg-0">
          <NavLink to={f?.link?.url || '/'} key={index}>
            <Content content={f?.link?.title || ''} />
          </NavLink>
        </Item>
      ))}
    </div>
  </StyledSubmenu>
)

export default Submenu
