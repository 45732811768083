import React from 'react'
import { ThemeProvider, createGlobalStyle } from 'styled-components'
import mainTheme from 'src/styles/Theme'

const GlobalStyle = createGlobalStyle`
  html, body {
    height: 100%;
    overflow: ${(props) => (props.isScrollable ? 'initial' : 'hidden')};
  }

  body {
    font-family: ${(props) => props.theme.font.family.primary};
    font-size: ${(props) => props.theme.font.size.medium};
    color: ${(props) => props.theme.color.dark};
  }

  h1, h2, h3, h4, h5, h6 {
    font-family: ${({ theme }) => theme.font.family.secondary};
    color: ${(props) => props.theme.color.primary};
  }

  & p {
    font-family: ${({ theme }) => theme.font.family.primary};
  }

  ${(props) =>
    Object.keys(props.theme.color).map(
      (key) =>
        `.bg-${key} { background-color: ${props.theme.color[key]}; }
        .text-${key} { color: ${props.theme.color[key]}; }`
    )}

  ${(props) =>
    Object.keys(props.theme.font.family).map(
      (key) =>
        `.font-family-${key} { font-family: ${props.theme.font.family[key]}; }`
    )}

  ${(props) =>
    Object.keys(props.theme.font.size).map(
      (key) => `.font-size-${key} { font-size: ${props.theme.font.size[key]}; }`
    )}

  ${(props) =>
    Object.keys(props.theme.font.weight).map(
      (key) =>
        `.font-weight-${key} { font-weight: ${props.theme.font.weight[key]}; }`
    )}

  a {
    color: inherit;

    &:hover {
      color: inherit;
    }
  }
`

const Theme = ({ children, isScrollable = true }) => (
  <ThemeProvider theme={mainTheme}>
    <GlobalStyle isScrollable={isScrollable} />
    {children}
  </ThemeProvider>
)

export default Theme
