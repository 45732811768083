import React from 'react'
import styled, { css } from 'styled-components'
import useMedia from 'use-media'

const Svg = styled.svg<{
  top?: number
  right?: number
  bottom?: number
  left?: number
  absolute?: boolean
  responsive?: boolean
  unit: '%' | 'px'
}>`
  overflow: visible;

  ${(props) =>
    props.absolute &&
    css`
      position: absolute;
    `}

  ${(props) =>
    props.top &&
    css`
      top: ${`${props.top}%`};
    `}

  ${(props) =>
    props.right &&
    css`
      right: ${`${props.right}${props.unit}`};
      @media (min-width: 2200px) {
        ${props.right < 0 &&
        props.responsive &&
        css`
          right: ${`${Math.abs(props.right) / 5}${props.unit}`};
        `}
      }
    `}

    ${(props) =>
    props.bottom &&
    css`
      bottom: ${`${props.bottom}${props.unit}`};
    `}

    ${(props) =>
    props.left &&
    css`
      left: ${props.left}${props.unit};
      @media (min-width: 2200px) {
        ${props.left < 0 &&
        props.responsive &&
        css`
          left: ${`${Math.abs(props.left) / 5}${props.unit}`};
        `}
      }
    `}
`

interface CircleProps {
  width: number
  height: number
  top?: number
  right?: number
  bottom?: number
  left?: number
  strokeWidth?: number
  absolute?: boolean
  identifier: string
  responsive?: boolean
  className?: string
  unit?: '%' | 'px'
}

const Circle = ({
  width,
  height,
  top,
  right,
  bottom,
  left,
  strokeWidth,
  absolute,
  identifier,
  responsive = true,
  className = '',
  unit = '%',
}: CircleProps) => {
  let circleWidth = width
  let circleHeight = height

  const mobile = useMedia({ maxWidth: 575 })

  const tablet = useMedia({ minWidth: 576 })

  const notebook = useMedia({ minWidth: 768 })

  const desktop = useMedia({ minWidth: 992 })

  if (responsive) {
    if (mobile) {
      circleWidth = width / 3
      circleHeight = height / 3
    }

    if (tablet) {
      circleWidth = width / 2.5
      circleHeight = height / 2.5
    }

    if (notebook) {
      circleWidth = width / 2
      circleHeight = height / 2
    }

    if (desktop) {
      circleWidth = width
      circleHeight = height
    }
  }

  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={circleWidth}
      height={circleHeight}
      fill="none"
      viewBox="0 0 283 283"
      top={top}
      right={right}
      bottom={bottom}
      left={left}
      absolute={absolute}
      responsive={responsive}
      className={className}
      unit={unit}
    >
      <circle
        cx="141.5"
        cy="141.5"
        r="136.5"
        stroke={`url(#${identifier})`}
        strokeWidth={strokeWidth || 10}
      />
      <defs>
        <linearGradient
          id={identifier}
          x1="85.349"
          x2="193.159"
          y1="-15.722"
          y2="298.722"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FEBD0D" />
          <stop offset="1" stopColor="#F38D11" />
        </linearGradient>
      </defs>
    </Svg>
  )
}

export default Circle
