import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'

// Elements
import Loading from 'components/shared/Loading'
import ParseContent from 'components/shared/ParseContent'

// Duuf
import FormDuuf, {
  FormDuufProps,
  FormDuufGroup,
  FormDuufSubmit,
  useFormDuuf,
} from '@ubo/form-duuf'

export { FormDuufGroup, FormDuufSubmit, useFormDuuf }

interface FormProps
  extends Omit<
    FormDuufProps,
    'graphqlData' | 'loadingComponent' | 'contentComponent'
  > {
  className?: string
  children?: React.ReactNode
}

const Form: React.FC<FormProps> = ({ className = '', children, ...rest }) => {
  const { allGfForm } = useStaticQuery<GatsbyTypes.formDuufQueryQuery>(graphql`
    query formDuufQuery {
      allGfForm {
        edges {
          node {
            formId
            slug
            apiURL
            formFields {
              id
              label
              labelPlacement
              type
              isRequired
              visibility
              placeholder
              checkboxLabel
              choices
              description
            }
            button {
              text
            }
            confirmations {
              message
            }
          }
        }
      }
    }
  `)

  return (
    <FormDuuf
      className={className}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...rest}
      graphqlData={allGfForm}
      // @ts-ignore
      renderLoading={Loading}
      renderStatus={ParseContent}
    >
      {children}
    </FormDuuf>
  )
}

export default Form
