/* eslint-disable react/no-array-index-key */
import React, { useState } from 'react'
import { motion } from 'framer-motion'
import styled from 'styled-components'

// Components
import Anydesk from 'components/elements/Modals/Anydesk'
import ButtonPrimarySmall, {
  ButtonPrimarySmallCSS,
} from 'components/elements/Buttons/ButtonPrimarySmall'
import useLayout from 'hooks/useLayout'

// Images
import Phone from 'img/phone.inline.svg'
import Arrow from 'img/arrow.inline.svg'

const Wrapper = styled(motion.div)<{ isSticky?: boolean }>`
  @media (min-width: 576px) {
    top: 15px;
  }
  @media (max-width: 575px) {
    top: 5px;
  }
`

const SupportButton = styled.div`
  ${ButtonPrimarySmallCSS}
  z-index: 2;

  & > div {
    color: #ffffff !important;
    font-family: ${({ theme }) => theme.font.family.primary};
    font-weight: ${({ theme }) => theme.font.weight.bold};
    padding: 4px 12.5px;
    width: 100%;
    height: 100%;
    display: flex;
    -webkit-text-decoration: none !important;
    text-decoration: none !important;

    @media (min-width: 576px) {
      font-size: ${({ theme }) => theme.font.size.medium};
    }

    @media (max-width: 575px) {
      font-size: ${({ theme }) => theme.font.size.small};
    }
  }

  @media (max-width: 575.98px) {
    & > div {
      font-size: 14px;
    }
  }
`

const Supportmenu = styled(motion.div)`
  top: 20px;
  background-color: ${({ theme }) => theme.color.light};
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  overflow: hidden;

  @media (min-width: 992px) {
    right: 2.25px;
    max-width: 118px;
  }

  @media (max-width: 991.98px) {
    right: 10px;
    max-width: 108px;
  }

  @media (max-width: 575px) {
    right: 2.5px;
  }
`

const SupportLink = styled.a`
  &:hover {
    background-color: ${({ theme }) => theme.color.grey};
  }

  &:last-child {
    border-bottom-left-radius: 12px;
    border-bottom-right-radius: 12px;
  }
`

const PopupLink = styled.button`
  &:hover {
    background-color: ${({ theme }) => theme.color.grey};
  }

  &:last-child {
    border-bottom-left-radius: 12px;
    border-bottom-right-radius: 12px;
  }
`

interface TopmenuProps {
  fields: any
  isSticky: boolean
}

const Topmenu = ({ fields, isSticky }: TopmenuProps) => {
  const [hovered, setHovered] = useState(false)
  const layout = useLayout()

  const { phonenumber, support } = fields.header.topmenuHeader

  return (
    <>
      <div className="container d-flex justify-content-end">
        <Wrapper
          className="position-relative d-flex align-items-center"
          initial={{ top: 15 }}
          animate={isSticky ? { top: 5 } : { top: 15 }}
        >
          <ButtonPrimarySmall isCustom to="#" className="me-4">
            <a href={phonenumber.url} className="d-flex align-items-center">
              <Phone className="mb-1 d-md-block d-none" />
              <span className="ms-md-2">{phonenumber.title}</span>
            </a>
          </ButtonPrimarySmall>
          <motion.div
            onHoverStart={() => setHovered(true)}
            onHoverEnd={() => setHovered(false)}
          >
            <motion.div
              onTapStart={() =>
                hovered ? setHovered(false) : setHovered(true)
              }
            >
              <SupportButton className="position-relative">
                <div className="d-flex align-items-center">
                  <span className="me-3">{support.link.title}</span>
                  <motion.span
                    initial={{ rotate: 0 }}
                    animate={hovered ? { rotate: 180 } : { rotate: 0 }}
                  >
                    <Arrow />
                  </motion.span>
                </div>
              </SupportButton>
            </motion.div>

            {hovered && (
              <Supportmenu
                className="position-absolute d-flex flex-column pt-3"
                initial={{ height: 0 }}
                animate={hovered ? { height: 'fit-content' } : { height: 0 }}
                exit={{ height: 0 }}
              >
                {support.submenu.map((item: any, index: number) => {
                  if (item.link.url.includes('anydesk')) {
                    return (
                      <PopupLink
                        key={index}
                        className="px-lg-4 px-3 py-2 text-left text-primary"
                        onClick={() => layout.setAnydeskModal(true)}
                      >
                        {item.link.title}
                      </PopupLink>
                    )
                  }

                  return (
                    <SupportLink
                      key={index}
                      href={item.link.url || '#'}
                      target="_blank"
                      rel="noreferrer"
                      className="px-lg-4 px-3 py-2 text-center"
                    >
                      {item.link.title}
                    </SupportLink>
                  )
                })}
              </Supportmenu>
            )}
          </motion.div>
        </Wrapper>
      </div>
      <Anydesk
        isOpen={layout.anydeskModal}
        closeModal={() => layout.setAnydeskModal(false)}
      />
    </>
  )
}

export default Topmenu
