import React from 'react'
import styled from 'styled-components'
import { AnimatePresence, motion } from 'framer-motion'

// Components
import Menu from 'components/layout/Header/Menu'
import Circle from 'components/elements/Vectors/Circle'

const MenuWrapper = styled(motion.div)`
  background-color: rgba(99, 109, 255, 0.97);
  position: fixed;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  z-index: 99;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  & ul {
    list-style: none;
    padding-left: 0;
    margin: 0;
    width: 70%;
  }
`

const NavButtonClose = styled.div`
  position: absolute;
  right: 20px;
  top: 20px;
  text-align: center;
  font-size: 0;
`

const NavButtonCloseCross = styled.div`
  position: relative;
  width: 32px;
  height: 32px;
  margin-left: 16px;

  &:before,
  &:after {
    background-color: ${(props) => props.theme.color.light};
    position: absolute;
    content: ' ';
    height: 33px;
    width: 2px;
  }

  &:before {
    transform: rotate(45deg);
  }

  &:after {
    transform: rotate(-45deg);
  }
`

const NavButton = styled.button`
  width: 80px;
  height: 50px;
  padding: 0 10px;
  text-align: center;
  z-index: 10;
  font-size: 0;
`

const NavButtonStripe = styled.div`
  background-color: ${(props) => props.theme.color.light};
  height: 2px;
  width: 50%;
  margin: 5px auto;
`

interface MobileProps {
  fields: GatsbyTypes.WpComponent
  setIsScrollable: Function
}

const Mobile = ({
  fields,
  setIsScrollable,
}: // eslint-disable-next-line no-unused-vars
MobileProps) => {
  const [isOpen, setIsOpen] = React.useState<boolean>(false)

  return (
    <div className="d-block d-lg-none">
      <NavButton
        role="button"
        onClick={() => {
          setIsOpen(!isOpen)
          setIsScrollable(false)
        }}
      >
        <NavButtonStripe />
        <NavButtonStripe />
        <NavButtonStripe />
        Menu
      </NavButton>
      <AnimatePresence>
        {isOpen && (
          <MenuWrapper
            exit={{
              opacity: 0,
              y: '100%',
            }}
            initial={{
              opacity: 0,
              y: '100%',
            }}
            animate={{
              opacity: 1,
              y: 0,
            }}
            transition={{
              duration: 0.2,
            }}
          >
            <Circle
              identifier="mobile-menu-circle-one"
              absolute
              width={600}
              height={600}
              top={15}
              right={2}
            />
            <Circle
              identifier="mobile-menu-circle-two"
              absolute
              width={300}
              height={300}
              bottom={20}
              right={25}
            />
            <Circle
              identifier="mobile-menu-circle-three"
              absolute
              width={150}
              height={150}
              bottom={10}
              left={40}
            />

            <NavButtonClose
              role="button"
              onClick={() => {
                setIsOpen(!isOpen)
                setIsScrollable(true)
              }}
            >
              <NavButtonCloseCross />
              Menu sluiten
            </NavButtonClose>
            <ul>
              <Menu fields={fields} />
            </ul>
            {/* <motion.div
              animate={{
                y: ['100%', '-100%'],
                x: [
                  '0%',
                  '100%',
                  '200%',
                  '300%',
                  '400%',
                  '500%',
                  '600%',
                  '700%',
                  '800%',
                  '900%',
                  '1000%',
                ],
              }}
              transition={bounceTransition}
            >
              <Circle
                className="position-relative"
                identifier="mobile-menu-circle-four"
                width={120}
                height={120}
                bottom={-450}
                left={-500}
              />
            </motion.div> */}
          </MenuWrapper>
        )}
      </AnimatePresence>
    </div>
  )
}

export default Mobile
