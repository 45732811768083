import React from 'react'
import styled from 'styled-components'
import { motion } from 'framer-motion'

// Components
import Menu from 'components/layout/Header/Menu'

const StyledDesktop = styled.div`
  list-style: none;
  margin-bottom: 0;
`

interface DesktopProps {
  fields: GatsbyTypes.WpComponent
  isSticky?: boolean
}

const Desktop = ({ fields, isSticky }: DesktopProps) => (
  <StyledDesktop className="d-none d-lg-block">
    <motion.div
      className="d-flex align-items-center"
      initial={{ height: 120 }}
      animate={isSticky ? { height: 70 } : { height: 120 }}
    >
      <Menu fields={fields} />
    </motion.div>
  </StyledDesktop>
)

export default Desktop
