/* eslint-disable react/no-array-index-key */
import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import styled from 'styled-components'

// Components
import NavLink from 'components/shared/NavLink'
import Circle from 'components/elements/Vectors/Circle'
import ButtonPrimary, {
  ButtonPrimaryCSS,
} from 'components/elements/Buttons/ButtonPrimary'
import { ButtonSecondaryCSS } from 'components/elements/Buttons/ButtonSecondary'
import ParseContent from 'components/shared/ParseContent'
import FormDuuf, {
  FormDuufGroup,
  FormDuufSubmit,
} from 'components/related/Form'

// Hooks
import useLayout from 'hooks/useLayout'

// Images
import Location from 'img/location.inline.svg'
import Phone from 'img/phone.inline.svg'
import Email from 'img/email.inline.svg'
import Linkedin from 'img/linkedin.inline.svg'
import Facebook from 'img/facebook.inline.svg'
import Instagram from 'img/instagram.inline.svg'

const Section = styled.div`
  background-color: ${(props) => props.theme.color.primary};
  color: ${(props) => props.theme.color.light};

  & h2 {
    font-size: ${({ theme }) => theme.font.size.medium};
    font-weight: ${({ theme }) => theme.font.weight.bold};
  }

  & a {
    color: ${({ theme }) => theme.color.light};

    &:hover {
      color: ${({ theme }) => theme.color.light};
      text-decoration: underline;
    }

    @media (max-width: 575px) {
      font-size: ${({ theme }) => theme.font.size.small};
    }
  }

  & ul {
    list-style: none;
  }
`

const Icon = styled.div`
  width: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
`

const Contact = styled.div`
  & a {
    font-size: 15px;

    &:hover {
      color: ${({ theme }) => theme.color.secondary};
    }
  }

  @media (max-width: 1399px) {
    & a {
      font-size: 14px;
    }
  }
`

const Socials = styled.div`
  & a:hover {
    opacity: 0.9;
  }
`
const MenuItem = styled(NavLink)``

const PopupItem = styled.a`
  &:hover {
    cursor: pointer;
  }
`

const NewsLetter = styled.div`
  background: ${({ theme }) => theme.color.secondary};

  & h2,
  p {
    color: ${({ theme }) => theme.color.light};
  }
`

const Content = styled(ParseContent)`
  color: ${({ theme }) => theme.color.light};
`

const StyledFormDuuf = styled(FormDuuf)`
  position: relative;
  color: ${({ theme }) => theme.color.primary};

  & .form-duuf-group {
    position: relative;
    margin-bottom: 1.5rem;
  }

  & .form-duuf-textarea,
  & .form-duuf-input {
    width: 100%;
    border-color: ${({ theme }) => theme.color.grey};
    border-radius: 50px;
    border-style: solid;
    border-width: 1px;
    min-height: 38px;
    padding-right: 15px;
    padding-left: 15px;
  }

  & .form-duuf-label {
    margin-bottom: 0.65rem !important;
    margin-left: 1rem !important;
    color: ${({ theme }) => theme.color.light};
  }

  & .form-duuf-checkbox-label {
    margin-left: 0.5rem;
    margin-bottom: 0;
  }

  & .form-duuf-label-mandatory {
    color: ${({ theme }) => theme.color.light};
  }

  & .form-duuf-error {
    position: absolute;
    right: 15px;
    top: 5px;
    color: tomato;
  }

  & .form-duuf-group-submit {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 0 !important;
  }

  & .form-duuf-submit {
    ${ButtonPrimaryCSS};
    background-color: ${({ theme }) => theme.color.primary};
    cursor: ;

    &-disabled {
      opacity: 1;
    }
  }

  & .form-duuf-confirm {
    color: ${({ theme }) => theme.color.primary};
    font-size: 20px !important;
    text-align: center;
  }

  & .form-duuf-something-wrong {
    color: red;
  }

  & .form-duuf-loading {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    background-color: rgba(255, 255, 255, 0.5);
  }
`

const Footer = () => {
  // eslint-disable-next-line
  const { fields } = useStaticQuery<GatsbyTypes.footerQueryQuery>(graphql`
    query footerQuery {
      fields: wpComponent(databaseId: { eq: 98 }) {
        footer {
          menuFooter {
            column {
              title
              menu {
                link {
                  title
                  url
                  target
                }
              }
            }
          }
          contactFooter {
            title
            address {
              title
              url
            }
            phonenumber {
              title
              url
            }
            email {
              title
              url
            }
            contact {
              title
              url
            }
            linkedin {
              title
              url
            }
            facebook {
              title
              url
            }
            instagram {
              title
              url
            }
          }
          newsletter {
            description
            formId
          }
        }
      }
    }
  `)

  const { menuFooter, contactFooter, newsletter }: any = fields?.footer

  const layout = useLayout()

  return (
    <>
      <NewsLetter>
        <div className="container">
          <div className="row py-5 align-items-center">
            <div className="col-lg-7 mb-4 mb-lg-0">
              <ParseContent content={newsletter.description} />
            </div>
            <div className="col-lg-5">
              <StyledFormDuuf id={4} generate={false}>
                <div className="row">
                  <div className="col-lg-6">
                    <FormDuufGroup hideIds={[1]} />
                  </div>
                  <div className="col-lg-6">
                    <FormDuufGroup showIds={[1]} />
                  </div>
                  <div className="mt-0">
                    <div className="d-flex justify-content-start">
                      <FormDuufSubmit />
                    </div>
                  </div>
                </div>
              </StyledFormDuuf>
            </div>
          </div>
        </div>
      </NewsLetter>
      <Section>
        <div className="container py-5">
          <div className="row">
            <div className="col-lg-7 mb-lg-5 mb-4">
              <div className="row">
                {menuFooter.column.map((node: any, index: number) => (
                  <div className="col-4" key={index}>
                    <Title>{node.title}</Title>
                    <ul className="d-flex flex-column ps-0">
                      {node.menu.map((linkNode: any, menuIndex: number) => {
                        if (linkNode.link.url.includes('#anydesk')) {
                          return (
                            <li key={menuIndex}>
                              <PopupItem
                                className="mb-2"
                                onClick={() => layout.setAnydeskModal(true)}
                              >
                                <Content content={linkNode.link.title} />
                              </PopupItem>
                            </li>
                          )
                        }
                        return (
                          <li key={menuIndex}>
                            <MenuItem
                              to={linkNode.link.url}
                              className="mb-2"
                              target={linkNode.link.target}
                            >
                              <Content content={linkNode.link.title} />
                            </MenuItem>
                          </li>
                        )
                      })}
                    </ul>
                  </div>
                ))}
              </div>
            </div>
            <Contact className="col-lg-5">
              <Title>{contactFooter.title}</Title>
              <div className="row">
                <div className="col-xl-7 mb-4 mb-sm-0">
                  <NavLink
                    to={contactFooter.address.url}
                    className="d-flex mb-xl-5 mb-3"
                    target="_blank"
                  >
                    <Icon>
                      <Location />
                    </Icon>
                    <span className="ms-2">{contactFooter.address.title}</span>
                  </NavLink>
                  <ButtonPrimary
                    to={contactFooter.contact.url}
                    className="mb-3 mb-xl-0"
                  >
                    {contactFooter.contact.title}
                  </ButtonPrimary>
                </div>
                <div className="col-xl-5">
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href={contactFooter.phonenumber.url}
                    className="d-flex align-items-start mb-1"
                  >
                    <Icon>
                      <Phone />
                    </Icon>
                    <span className="ms-2">
                      {contactFooter.phonenumber.title}
                    </span>
                  </a>
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href={contactFooter.email.url}
                    className="d-flex align-items-start mb-4 pb-3"
                  >
                    <Icon>
                      <Email />
                    </Icon>
                    <span className="ms-2">{contactFooter.email.title}</span>
                  </a>
                  <Socials className="d-flex justify-content-center">
                    <a
                      href={contactFooter.linkedin.url}
                      className="text-hidden me-2"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <Linkedin className="rounded-circle" />
                      {contactFooter.linkedin.title}
                    </a>
                    <a
                      href={contactFooter.instagram.url}
                      className="text-hidden me-2"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <Instagram className="rounded-circle" />
                      {contactFooter.instagram.title}
                    </a>
                  </Socials>
                </div>
              </div>
            </Contact>
          </div>
        </div>
      </Section>
    </>
  )
}

const Title: React.FC = ({ children }) => (
  <div className="position-relative d-flex align-items-center justify-content-start mb-4">
    <Circle
      className="d-sm-block d-none"
      identifier="footer-sm-circle"
      absolute
      width={12}
      height={12}
      strokeWidth={99}
      left={-22}
      responsive={false}
      unit="px"
    />
    <Circle
      className="d-sm-none"
      identifier="footer-circle"
      absolute
      width={10}
      height={10}
      strokeWidth={99}
      left={0}
      responsive={false}
      unit="px"
    />
    <h2 className="mb-0 ps-sm-0 ps-3 text-white">{children}</h2>
  </div>
)

export default Footer
