import React from 'react'
import styled from 'styled-components'
import SyntaxHighlighter from 'react-syntax-highlighter'
import { atomOneDark } from 'react-syntax-highlighter/dist/esm/styles/hljs'

const CodeWrapper = styled.div`
  & > pre {
    border-radius: 12px;
  }
`

interface BlockCodeProps {
  text: any
}

const BlockCode = ({ text }: BlockCodeProps) => (
  <CodeWrapper>
    <SyntaxHighlighter language="powershell" style={atomOneDark}>
      {text}
    </SyntaxHighlighter>
  </CodeWrapper>
)

export default BlockCode
